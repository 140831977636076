<template>
  <div class="companies-map">
    <ve-bmap
      ref="mapRef"
      height="100%"
      :settings="chartSettings"
      :series="chartSeries"
      :tooltip="chartTooltip"
      :events="chartEvents"
    ></ve-bmap>
    <div class="chain-block-box" v-show="companyList.length > 0">
      Hash值区块链验证:
      <span v-if="!hashFlag" class="hash-value" @click="verifyHash">
        <i class="el-icon-thumb"></i>
        {{ hashVal }}
      </span>
      <span v-else class="hash-result" @click="verifyHash">数据无篡改</span>
    </div>
    <div class="company-list-box">
      <div style="text-align: right">
        <el-button
          v-if="companyListFlag"
          size="small"
          type="warning"
          icon="el-icon-minus"
          @click="companyListFlag = false"
          >收起</el-button>
        <el-button
          v-else
          size="small"
          type="primary"
          icon="el-icon-office-building"
          @click="companyListFlag = true"
          >展开</el-button
        >
      </div>
      <el-collapse-transition>
        <div v-show="companyListFlag" class="company-list">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <el-input
                style="width: 100%"
                v-model="listQuery.company_name"
                size="mini"
                placeholder="输入关键字搜索"
                @input="handleInput"
              >
                <template slot="prepend">生产经营者名称</template>
              </el-input>
            </div>
            <!-- <div>
              <el-table
                style="background:transparent"
                border
                fit
                :data="companyList.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
                :show-header="false"
                highlight-current-row
                :max-height="500"
                @row-click="handleRowClick"
              >
                <el-table-column prop="name" align="center"></el-table-column>
              </el-table>
            </div>-->
            <div>
              <el-table
                style="background: transparent"
                border
                fit
                :data="companyList"
                :show-header="false"
                highlight-current-row
                :max-height="500"
                @row-click="handleRowClick"
              >
                <el-table-column
                  prop="companyName"
                  align="center"
                ></el-table-column>
              </el-table>
              <el-pagination
                small
                background
                hide-on-single-page
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 50]"
                :page-size="parseInt(listQuery.pager_openset)"
                layout=" prev, pager, next"
                :total="total"
              ></el-pagination>
            </div>
          </el-card>
        </div>
      </el-collapse-transition>
    </div>
    <div style="display: none">
      <div ref="customInfowindow">
        <h4 style="margin: 0 0 5px 0; padding: 0.2em 0">
          名称:{{ companyInfo.company_name }}
        </h4>
        <div style="margin-top: 5px">
          <i class="el-icon-location"></i>
          <span>地址:{{ companyInfo.address }}</span>
        </div>
        <div style="margin-top: 10px">
          <el-row :gutter="20">
            <el-col :span="12">
              <!-- <div class="grid-content bg-purple">
                <el-image
                  v-if="companyInfo.blc_credit"
                  :src="companyInfo.blc_credit"
                  style="width:100px;height:150px;"
                  :preview-src-list="[companyInfo.blc_credit]"
                ></el-image>
                <span v-else style="color:#F56C6C">无营业执照</span>
              </div>-->
            </el-col>
            <el-col :span="12">
              <!-- <div class="grid-content bg-purple">
                <el-image
                  v-if="companyInfo.sc_credit"
                  style="width:100px;height:150px;"
                  :src="companyInfo.sc_credit"
                  :preview-src-list="[companyInfo.sc_credit]"
                ></el-image>
                <span v-else style="color:#F56C6C">无生产经营许可证</span>
              </div>-->
            </el-col>
          </el-row>
        </div>
<!--        <el-button-->
<!--          style="margin-top: 10px"-->
<!--          type="primary"-->
<!--          @click="handleSup"-->
<!--          size="small"-->
<!--          >监管</el-button-->
<!--        >-->
        <el-button
          style="margin-top: 10px"
          type="success"
          @click="handleRecord"
          size="small"
          >收发货详情</el-button
        >
        <el-button
          style="margin-top: 10px"
          type="warning"
          @click="handleStorage"
          size="small"
          >库存详情</el-button
        >
        <!--        <br />-->
        <el-button
          style="margin-top: 10px"
          type="primary"
          @click="handleUnit"
          size="small"
          >供销单位</el-button
        >
        <br />
        <el-button
          style="margin-top: 10px"
          type="warning"
          @click="handleTable1"
          size="small"
          >区域信息</el-button
        >
        <el-button
          style="margin-top: 10px"
          type="success"
          @click="handleTable2"
          size="small"
          >产品信息</el-button
        >
        <el-button
                style="margin-top: 10px"
                type="primary"
                @click="handleTable3"
                size="small"
        >销售收款信息</el-button
        >

        <el-button
                style="margin-top: 10px"
                type="primary"
                @click="handleCam"
                size="small"
        >监控</el-button
        ><el-button
                style="margin-top: 10px"
                type="primary"
                @click="handleHACCP"
                size="small"
        >HACCP</el-button
        >
        <el-button
                style="margin-top: 10px"
                type="primary"
                @click="handleEvaluate"
                size="small"
        >评价分析</el-button
        >
        <el-button
                style="margin-top: 10px"
                type="primary"
                @click="handleCateringHACCP"
                size="small"
        >门店食安管理</el-button
        >
      </div>
    </div>

    <el-dialog title="监管" :visible.sync="supDialogVisible" width="25%">
      <el-form label-width="100px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="评级">
              <el-select v-model="level" placeholder="请选择">
                <el-option
                  v-for="item in levelOptions"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" style="margin-bottom: 15px">
            <el-form-item label="违法违规">
              <el-select v-model="reason" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" style="margin-bottom: 15px">
            <el-form-item label="监管文书上传">
              <el-upload
                class="upload-demo"
                :action="actionPath"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                :limit="1"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="supDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleCreateSup">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
      title="记录"
      :visible.sync="recordDialogVisible"
      width="75%"
      @close="restFilterRecordForm"
    >
      <el-form inline :model="filterRecordForm">
        <el-form-item size="small" style="margin-right: 30px">
          <el-input
            size="small"
            v-model="filterRecordForm.goodsName"
            placeholder="商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item size="small" style="margin-right: 30px">
          <el-input
            size="small"
            v-model="filterRecordForm.batchNumber"
            style="width: 150px"
            placeholder="批号"
          ></el-input>
        </el-form-item>
        <el-form-item size="small" style="margin-right: 30px">
          <el-input
            size="small"
            v-model="filterRecordForm.gtin"
            placeholder="货物品码"
          ></el-input>
        </el-form-item>
        <el-form-item size="small" style="margin-right: 30px">
          <el-input
            size="small"
            v-model="filterRecordForm.carNumber"
            placeholder="车牌号"
          ></el-input>
        </el-form-item>
        <el-form-item size="small" style="margin-right: 30px">
            <el-radio-group v-model="filterRecordForm.goodsType">
                 <el-radio :label="5">全部</el-radio>      {{listQuery.department_type}}
           <el-radio :label="1">进口</el-radio>
           <el-radio :label="2">国产</el-radio>
          <el-radio v-if="listQuery.department_type==2 || listQuery.department_type==1" :label="3">农产品</el-radio>
          <el-radio v-if="listQuery.department_type==2 || listQuery.department_type==1" :label="4">农资</el-radio>
        </el-radio-group>
        </el-form-item>
        <el-form-item size="small">
          <el-button type="primary" @click="handleRecord" size="small"
            >筛选</el-button
          >
        </el-form-item>
      </el-form>
      <el-tabs type="border-card">
        <el-tab-pane label="发货记录">
          <el-table
            border
            fit
            :data="recordData.send_out_records"
            empty-text="暂无数据"
            height="400"
            @sort-change="recordSortChange($event, 1)"
          >
            <el-table-column align="center" label="商品类型">
              <template slot-scope="scope">
                <span>{{ scope.row.goodsCategory | infoFilter }}</span>
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              property="createdTime"
              label="发货日期"
            ></el-table-column>
            <el-table-column
              align="center"
              property="goodsName"
              label="商品名称"
            ></el-table-column>
            <el-table-column align="center" label="二维码值/条形码">
              <template slot-scope="scope">
                <span>{{ scope.row.gtin }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              property="batchNumber"
              label="批号"
            ></el-table-column>
            <el-table-column
              align="center"
              property="toCompanyName"
              label="收货方"
            ></el-table-column>
            <el-table-column
              align="center"
              property="goodsWeight"
              label="统计"
            ></el-table-column>
            <el-table-column
              align="center"
              property="goodsUnitPrice"
              label="发货单价(元)"
              min-width="100px"
            ></el-table-column>
            <el-table-column
              align="center"
              property="goodsTotalPrice"
              label="发货总价(元)"
            ></el-table-column>
            <!-- <el-table-column align="center" label="状态">
              <template slot-scope="scope">
                <el-tag :type="scope.row.status | tagFormat">{{
                  scope.row.status | statusFormat
                }}</el-tag>
              </template>
            </el-table-column> -->
            <el-table-column
              align="center"
              label="区块链Hash值"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span
                  v-if="!scope.row.hashFlag"
                  @click="scope.row.hashFlag = !scope.row.hashFlag"
                  style="cursor: pointer"
                  >{{ scope.row.hashVal }}</span
                >
                <span
                  v-else
                  style="color: #67c23a; cursor: pointer"
                  @click="scope.row.hashFlag = !scope.row.hashFlag"
                  >数据无篡改</span
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              property="carNumber"
              label="车牌号"
            ></el-table-column>
            <el-table-column
              align="center"
              property="consignerName"
              label="操作人员"
            ></el-table-column>
            <el-table-column
              align="center"
              property="remark"
              label="备注"
            ></el-table-column>
            <el-table-column align="center" label="操作" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleViewPic(scope.row)"
                  >查看图片</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-container">
            <el-pagination
              background
              hide-on-single-page
              :page-size="20"
              @current-change="handleCurrentChangeSr"
              :current-page="srlistPage"
              layout="total, prev, pager, next, jumper"
              :total="recordData.send_out_records_total"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="收货记录">
          <el-table
            border
            fit
            :data="recordData.received_records"
            empty-text="暂无数据"
            height="400"
            @sort-change="recordSortChange($event, 2)"
          >
            <el-table-column align="center" label="商品类型">
              <template slot-scope="scope">
                <span>{{ scope.row.goodsCategory | infoFilter }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              property="createdTime"
              label="收货日期"
            ></el-table-column>
            <el-table-column
              align="center"
              property="goodsName"
              label="商品名称"
            ></el-table-column>
            <el-table-column align="center" label="二维码值/条形码">
              <template slot-scope="scope">
                <span>{{ scope.row.gtin }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              property="batchNumber"
              label="批号"
            ></el-table-column>
            <el-table-column
              align="center"
              property="fromCompanyName"
              label="发货方"
            ></el-table-column>
            <el-table-column
              align="center"
              property="goodsWeight"
              label="统计"
            ></el-table-column>
            <el-table-column
              align="center"
              property="goodsUnitPrice"
              label="发货单价(元)"
              min-width="100px"
            ></el-table-column>
            <el-table-column
              align="center"
              property="goodsTotalPrice"
              label="发货总价(元)"
            ></el-table-column>
            <el-table-column
              align="center"
              property="toGoodsUnitPrice"
              label="收货单价(元)"
              min-width="100px"
            ></el-table-column>
            <el-table-column
              align="center"
              property="toGoodsTotalPrice"
              label="收货总价(元)"
            ></el-table-column>
            <el-table-column
              align="center"
              label="区块链Hash值"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span
                  v-if="!scope.row.hashFlag"
                  @click="scope.row.hashFlag = !scope.row.hashFlag"
                  style="cursor: pointer"
                  >{{ scope.row.hashVal }}</span
                >
                <span
                  v-else
                  style="color: #67c23a; cursor: pointer"
                  @click="scope.row.hashFlag = !scope.row.hashFlag"
                  >数据无篡改</span
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              property="carNumber"
              label="车牌号"
            ></el-table-column>
            <el-table-column
              align="center"
              property="consignerName"
              label="操作人员"
            ></el-table-column>
            <el-table-column
              align="center"
              property="remark"
              label="备注"
            ></el-table-column>
            <el-table-column align="center" label="操作" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="primary"
                  @click="handleViewPic(scope.row)"
                  >查看图片</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-container">
            <el-pagination
              background
              hide-on-single-page
              :page-size="20"
              @current-change="handleCurrentChangeRr"
              :current-page="rrlistPage"
              layout="total, prev, pager, next, jumper"
              :total="recordData.received_records_total"
            ></el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <el-dialog
      title="HACCP"
      :visible.sync="showHACCP"
      width="55%"
      @close="restFilterForm"
    >
      <div style="display: flex;padding: 20px 0">
        <div style="width: 300px;margin-right: 20px">
            <el-input
              size="small"
              v-model="haccpQuery1.keyword"
              placeholder="生产线名"
            ></el-input>
        </div>
        <div>
          <el-button size="small" type="primary" @click="handleHACCP('search')"
            >搜索</el-button
          >
        </div>
      </div>
      <el-table
        border
        fit
        :data="haccpList"
        empty-text="暂无数据"
        height="800"
        :stripe="true"
      >
        <el-table-column
          align="center"
          property="goodsName"
          label="生产线名"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建日期">
          <template slot-scope="scope">
            <span>{{ scope.row.createdTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="batchNumber"
          label="生产批次数"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.count }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="stock"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button type="primary" @click="goHACCPDetail(scope.row.id)">查看详情</el-button>
          </template>
        </el-table-column>

      </el-table>

      <div style="padding: 20px;text-align: center">
        <el-pagination
                background
                :page-size="10"
                layout="total,prev, pager, next"
                :total="haccpTotal"
                :current-page="haccpCurrentPage"
                @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </el-dialog>

    <el-dialog
      title="评价分析"
      :visible.sync="showEvaluate"
      width="80%"
    >
      <div v-if="showEvaluateChart" class="app-container calendar-list-container evaBg"
      >
        <div class="filter-container" style="text-align: right;padding: 10px 30px 0 0">
          <div @click="checkEvaluate" class="button">切换图表</div>
        </div>
        <el-row
                v-if="evaluateList.length>0"
                :gutter="10"
        >
          <el-col
                  v-for="(item,index) in evaluateList"
                  :key="index"
                  :span="8"
                  style="margin-bottom:10px;"
          >
            <div style="padding: 30px">
              <el-card
                      class="box-card"
                      shadow="hover"
              >
                <div
                        slot="header"
                        class="clearfix"
                        style="text-align:center;height:40px;"
                >
                  <span style="color:#fff">{{item.name}}</span>
                </div>
                <div >
                  <ve-ring
                          :data="item.chartData"
                          :settings="chartSettingsEvaluate"
                          :extend="chartExtendEvaluate"
                  ></ve-ring>
                </div>
              </el-card>

            </div>
          </el-col>
        </el-row>
        <div
                v-else
                style="text-align:center"
        >无数据</div>
      </div>


      <div v-else>
        <div class="app-container calendar-list-container">
          <div class="filter-container" style="text-align: right;padding: 10px 30px 10px 0">
            <div @click="showEvaluateChart = true" class="button">切换图表</div>
          </div>

          <el-table
                  :key="tableKey"
                  ref="evaluateTable"
                  :data="evaluateTableList"

                  element-loading-text="加载中..."
                  border
                  fit
                  highlight-current-row
                  style="width: 100%"
          >
            <el-table-column type="index" align="center" label="序号" width="100" />
            <el-table-column min-width="40" align="center" label="姓名">
              <template slot-scope="scope">
                <span>{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column min-width="150" align="center" label="意见">
              <template slot-scope="scope">
                <span style="color:#E6A23C">{{ scope.row.tasteType }}</span>
              </template>
            </el-table-column>
            <el-table-column min-width="100" align="center" label="其他建议">
              <template slot-scope="scope">
                <span>{{ scope.row.description }}</span>
              </template>
            </el-table-column>
            <el-table-column width="80" align="center" label="图片">
              <template slot-scope="scope">
                <el-button
                        v-if="scope.row.imgList.length>0"
                        icon="el-icon-picture"
                        type="primary"
                        size="mini"
                        circle
                        @click="showImg(scope.row)"
                />
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-container">
            <el-pagination
                    background
                    :current-page="listQuery.page"
                    :page-sizes="[10,20,30, 50]"
                    :page-size="listQuery.count"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    @size-change="handleEvaluateSizeChange"
                    @current-change="handleEvaluateCurrentChange"
            />
          </div>


        </div>
      </div>


    </el-dialog>
    <el-dialog title="查看图片" :visible.sync="imgVisible">

      <el-image
              v-for="(img,imgIndex) in imgList"
              :key="imgIndex"
              style="width: 300px; height: 300px"
              :src="img"
              fit="cover"
              :preview-src-list="imgList"
              class="mr20 t-border mb10"
      ></el-image>

    </el-dialog>
    <el-dialog
      title="HACCP"
      :visible.sync="showHACCPDetail"
      width="55%"
      @close="restFilterForm"
    >
<!--      <div style="display: flex;padding: 20px 0">-->
<!--        <div style="width: 300px;margin-right: 20px">-->
<!--          <el-input-->
<!--                  size="small"-->
<!--                  v-model="haccpQuery2.keyword"-->
<!--                  placeholder="生产线名"-->
<!--          ></el-input>-->
<!--        </div>-->
<!--        <div>-->
<!--          <el-button size="small" type="primary" @click="goHACCPDetail"-->
<!--          >搜索</el-button-->
<!--          >-->
<!--        </div>-->
<!--      </div>-->
      <el-table
        border
        fit
        :data="haccpDetailList"
        empty-text="暂无数据"
        height="800"
        :stripe="true"
        :highlight-current-row="true"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div>
<!--              <el-steps :align-center="true" space="200px">-->
<!--                <el-step v-for="(item,index) in scope.row.detail" :key="index">-->
<!--                  <template slot="icon">-->
<!--                    <div style="font-size: 36px">-->
<!--                      <div v-if="item.active" class="activated">-->
<!--                        <i class="el-icon-success"></i>-->
<!--                      </div>-->
<!--                      <div v-else >-->
<!--                        <i class="el-icon-error"></i>-->
<!--                      </div>-->
<!--                    </div>-->

<!--                  </template>-->
<!--                  <template slot="title">-->
<!--                    <div :class="{'activated' : item.active}" style="padding: 5px 0">-->
<!--                      {{item.name}}-->
<!--                    </div>-->
<!--                  </template>-->
<!--                  <template slot="description">-->
<!--                    <div :class="{'activated' : item.active}">-->
<!--                      {{item.active ? '已完成' : '未完成'}}-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </el-step>-->
<!--              </el-steps>-->
              <custom-step :companyId="companyId" :haccpType="haccpType" :steps-data="scope.row.processPoint" :active="1"></custom-step>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="goodsName"
          label="生产批次"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="日期">
          <template slot-scope="scope">
            <span>{{ scope.row.createdTime.replace('T',' ') }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="batchNumber"
          label="HACCP点完成比"
        >
          <template slot-scope="scope">
            <el-progress :text-inside="true" :stroke-width="24" :percentage="scope.row.finishingRate" status="success"></el-progress>
          </template>
        </el-table-column>
      </el-table>
      <div style="padding: 20px;text-align: center">
        <el-pagination
                background
                :page-size="10"
                layout="total,prev, pager, next"
                :total="haccpDetailTotal"
                :current-page="haccpDetailCurrentPage"
                @current-change="currentDetailChange"
        >
        </el-pagination>
      </div>

    </el-dialog>


    <el-dialog
      title="库存"
      :visible.sync="storageDialogVisible"
      width="55%"
      @close="restFilterForm"
    >
      <el-form inline :model="filterForm">
<!--        <el-form-item size="small">-->
<!--          <el-input-->
<!--            size="small"-->
<!--            v-model="filterForm.goodsName"-->
<!--            placeholder="商品名称"-->
<!--          ></el-input>-->
<!--        </el-form-item>-->
        <el-form-item size="small">
          <el-input
            size="small"
            v-model="filterForm.gtin"
            placeholder="二维码值/条形码"
          ></el-input>
        </el-form-item>
        <el-form-item size="small">
          <el-input
            size="small"
            v-model="filterForm.batchNumber"
            placeholder="批号"
          ></el-input>
        </el-form-item>
        <el-form-item size="small">
          <el-checkbox
            v-model="filterForm.flag"
            :true-label="1"
            :false-label="0"
            @change="handleStorage"
            >以品类统计</el-checkbox
          >
        </el-form-item>
        <!-- <br /> -->
        <!-- <el-form-item label="按出厂时长排序" style="margin-right:30px;">
          <el-switch
            v-model="filterForm.time_sort"
            active-text="升序"
            inactive-text="降序"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>
        <el-form-item label="按库存量排序" style="margin-right:30px;">
          <el-switch
            v-model="filterForm.stock_sort"
            active-text="升序"
            inactive-text="降序"
            :active-value="1"
            :inactive-value="0"
          ></el-switch>
        </el-form-item>-->
        <el-form-item>
          <el-button size="small" type="primary" @click="handleStorage"
            >筛选</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        border
        fit
        :data="storageData"
        empty-text="暂无数据"
        height="350"
      >
        <el-table-column
          align="center"
          property="goodsName"
          label="商品名称"
        ></el-table-column>
        <el-table-column align="center" label="二维码值/条形码">
          <template slot-scope="scope">
            <span>{{ scope.row.gtin }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="batchNumber"
          label="批号"
          v-if="!filterForm.flag"
        ></el-table-column>
<!--        <el-table-column-->
<!--          align="center"-->
<!--          property="fromWeight"-->
<!--          label="发货重量"-->
<!--        ></el-table-column>-->
<!--        <el-table-column-->
<!--          align="center"-->
<!--          property="toWeight"-->
<!--          label="收货重量"-->
<!--        ></el-table-column>-->
        <el-table-column
          align="center"
          property="stock"
          label="库存"
        ></el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="查看图片" :visible.sync="picDialogVisible" top="8vh">
      <div v-show="reportList.length > 0">
        <el-divider content-position="left">
          <el-tag>资证报告</el-tag>
        </el-divider>
        <el-row :gutter="10">
          <el-col :span="6" v-for="(item, index) in reportList" :key="index">
            <el-image
              style="height: 200px"
              :src="item"
              :preview-src-list="reportList"
            ></el-image>
          </el-col>
        </el-row>
      </div>
      <div v-show="attachList.length > 0">
        <el-divider content-position="left">
          <el-tag type="success">收发货实景</el-tag>
        </el-divider>
        <el-row :gutter="10">
          <el-col :span="6" v-for="(item, index) in attachList" :key="index">
            <el-image
              style="height: 200px"
              :src="item"
              :preview-src-list="attachList"
            ></el-image>
          </el-col>
        </el-row>
      </div>
    </el-dialog>

    <el-dialog title="供销单位" :visible.sync="unitDialogVisible" width="55%">
      <!-- <el-form inline :model="filterForm">
        <el-form-item size="small" label="商品名称">
          <el-input size="small" v-model="filterForm.company_name"></el-input>
        </el-form-item>
        <el-form-item size="small" label="批号">
          <el-input size="small" v-model="filterForm.batch_search"></el-input>
        </el-form-item>
        <el-form-item size="small" label="二维码值/条形码">
          <el-input size="small" v-model="filterForm.code_search"></el-input>
        </el-form-item>
        <el-form-item size="small" label="以品类统计">
          <el-checkbox
            v-model="filterForm.flag"
            :true-label="1"
            :false-label="0"
            @change="handleStorage"
          ></el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="handleStorage"
            >筛选</el-button
          >
        </el-form-item>
      </el-form> -->
      <el-table border fit :data="unitData" empty-text="暂无数据" height="350">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form
              label-position="left"
              inline
              class="cp-table-expand"
              label-width="100px"
            >
              <el-form-item label="类型">
                <span>{{ props.row.companyType }}</span>
              </el-form-item>
              <el-form-item label="法定代表人">
                <span>{{ props.row.legalPersonName }}</span>
              </el-form-item>
              <!-- <el-form-item label="成立资本">
                <span>{{ props.row.bl_reg_capital }}</span>
              </el-form-item> -->
              <el-form-item label="营业执照" v-if="props.row.creditUrl">
                <el-button
                  type="primary"
                  size="mini"
                  @click="handleViewPic1(props.row.creditUrl)"
                  >查看</el-button
                >
              </el-form-item>
              <el-form-item label="成立日期">
                <span>{{ props.row.issueTime }}</span>
              </el-form-item>
              <el-form-item label="有效期至">
                <span>{{ props.row.unusefulTime }}</span>
              </el-form-item>
              <el-form-item label="身份证正面" v-if="props.row.idCardFontUrl">
                <el-button
                  type="primary"
                  size="mini"
                  @click="handleViewPic1(props.row.idCardFontUrl)"
                  >查看</el-button
                >
              </el-form-item>
              <el-form-item label="身份证背面" v-if="props.row.idCartBackUrl">
                <el-button
                  type="primary"
                  size="mini"
                  @click="handleViewPic1(props.row.idCartBackUrl)"
                  >查看</el-button
                >
              </el-form-item>
              <el-form-item label="联系人">
                <span>{{ props.row.contact_user }}</span>
              </el-form-item>
              <el-form-item label="联系人电话">
                <span>{{ props.row.mobilephone }}</span>
              </el-form-item>
              <el-form-item label="经营范围">
                <span>{{ props.row.businessNames }}</span>
              </el-form-item>
              <!-- <el-form-item label="省">
                <span>{{ props.row.province }}</span>
              </el-form-item>
              <el-form-item label="市">
                <span>{{ props.row.city }}</span>
              </el-form-item>
              <el-form-item label="区/县">
                <span>{{ props.row.area }}</span>
              </el-form-item>
              <el-form-item label="街道/镇">
                <span>{{ props.row.street }}</span>
              </el-form-item>
              <el-form-item label="居委会">
                <span>{{ props.row.village }}</span>
              </el-form-item>
              <el-form-item label="经营范围">
                <span>{{ props.row.bl_dom }}</span>
              </el-form-item> -->
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="companyName"
          label="公司名称"
        ></el-table-column>
        <el-table-column
          align="center"
          property="creditCode"
          label="统一社会信用代码"
        ></el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="区域信息" :visible.sync="table1DialogVisible" width="55%">
      <!-- <el-row class="box search mt20" :gutter="10">
        <el-form
          :inline="true"
          :model="listQuery1"
          class="w"
          status-icon
          style="text-align: left"
        >
          <el-form-item size="small">
            <el-input
              placeholder="请输入企业名称"
              v-model="listQuery1.company_name"
              clearable
              class="input-with-select"
              @clear="initData1"
            >
            </el-input>
          </el-form-item>
          <el-form-item size="small">
            <el-button
              type="primary"
              @click="initData1"
              class="general_bgc general_border cfff"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </el-row> -->
      <el-table
        :data="list1"
        v-loading="listLoading1"
        border
        fit
        style="width: 100%"
      >
        <el-table-column label="区域名称" prop="name" align="center">
        </el-table-column>
        <el-table-column label="区域地址" prop="address" align="center">
        </el-table-column>
        <el-table-column label="区域面积" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.area + "(" + scope.row.unit + ")" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="区域图片" align="center">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              :disabled="scope.row.imgs.length == 0"
              @click="handleViewPic2(scope.row.imgs)"
              >查看图片{{
                scope.row.imgs.length ? "(" + scope.row.imgs.length + "张)" : ""
              }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="联系人" align="center" prop="concat">
        </el-table-column>
        <el-table-column label="联系电话" align="center" prop="concat_phone">
        </el-table-column>
        <el-table-column label="创建时间" align="center" prop="created_time">
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          background
          hide-on-single-page
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page="currentPage1"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="Number(listQuery1.count)"
          layout="total, sizes, prev, pager, next, jumper"
          :total="listTotal1"
        ></el-pagination>
      </div>
    </el-dialog>

    <el-dialog title="产品信息" :visible.sync="table2DialogVisible" width="55%">
      <el-row class="box search mt20" :gutter="10">
        <el-form
          :inline="true"
          :model="listQuery2"
          class="w"
          status-icon
          style="text-align: left"
        >
          <el-form-item size="small">
            <el-input
              placeholder="请输入产品名称"
              v-model="listQuery2.name"
              clearable
              class="input-with-select"
              @clear="initData2"
            >
            </el-input>
          </el-form-item>
          <el-form-item size="small">
            <el-input
              placeholder="请输入区域名称"
              v-model="listQuery2.base_name"
              clearable
              class="input-with-select"
              @clear="initData2"
            >
            </el-input>
          </el-form-item>
          <el-form-item size="small">
            <el-button
              type="primary"
              @click="initData2"
              class="general_bgc general_border cfff"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </el-row>
      <el-table
        :data="list2"
        v-loading="listLoading2"
        border
        fit
        style="width: 100%"
      >
        <el-table-column label="产品名称" prop="name" align="center">
        </el-table-column>
        <el-table-column label="所属区域" prop="base_name" align="center">
        </el-table-column>
        <el-table-column label="种养数量" align="center">
          <template slot-scope="scope">
            <span>{{
              scope.row.planting_count + "(" + scope.row.unit + ")"
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="种养状态" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.state | tagFilter">{{
              scope.row.state | statusFilter
            }}</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column label="创建者" align="center" prop="user_name">
      </el-table-column> -->
        <el-table-column label="创建时间" align="center" prop="created_time">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              @click="handleGoods(scope.row.id)"
              >绑码详情</el-button
            >
            <br />
            <el-button
              style="margin-top: 5px"
              size="small"
              type="success"
              @click="handleFarmRecord(scope.row.id)"
              >农事农活记录</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
          background
          hide-on-single-page
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :current-page="currentPage2"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="Number(listQuery2.count)"
          layout="total, sizes, prev, pager, next, jumper"
          :total="listTotal2"
        ></el-pagination>
      </div>
    </el-dialog>
    <el-dialog title="绑码详情" :visible.sync="goodsDialogVisible" width="55%">
      <el-table
        border
        fit
        :data="plantingGoodsList"
        empty-text="暂无数据"
        height="450"
      >
        <el-table-column
          align="center"
          property="goods_name"
          label="产品名称"
        ></el-table-column>
        <el-table-column align="center" label="二维码值/条形码">
          <template slot-scope="scope">
            <span>{{ scope.row.gtin }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="batch_number"
          label="批号"
        ></el-table-column>
        <el-table-column
          align="center"
          property="goods_weight"
          label="重量(kg)"
        ></el-table-column>
        <el-table-column label="绑码实景" align="center" min-width="100">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              :disabled="scope.row.imgs && scope.row.imgs.length == 0"
              @click="handleViewPic2(scope.row.imgs)"
              >查看图片{{
                scope.row.imgs && scope.row.imgs.length
                  ? "(" + scope.row.imgs.length + "张)"
                  : ""
              }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="print_count"
          label="打印次数"
        ></el-table-column>
        <el-table-column
          align="center"
          property="screenshot_count"
          label="截图次数"
        ></el-table-column>
        <el-table-column
          align="center"
          property="sales"
          label="销售量"
        ></el-table-column>
        <el-table-column
          align="center"
          property="create_name"
          label="绑码者"
        ></el-table-column>
        <el-table-column
          align="center"
          property="created_time"
          label="绑码时间"
        ></el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          background
          hide-on-single-page
          @size-change="handleSizeChange3"
          @current-change="handleCurrentChange3"
          :current-page="currentPage3"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="Number(listQuery3.count)"
          layout="total, sizes, prev, pager, next, jumper"
          :total="plantingGoodsListTotal"
        ></el-pagination>
      </div>
    </el-dialog>

    <el-dialog
      title="农事农活记录"
      :visible.sync="farmDialogVisible"
      width="55%"
    >
      <el-table
        border
        fit
        :data="farmRecordList"
        empty-text="暂无数据"
        height="450"
      >
        <el-table-column
          align="center"
          property="name"
          label="名称"
        ></el-table-column>
        <el-table-column align="center" label="人工时费用">
          <template slot-scope="scope">
            <span>{{ scope.row.cost }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="remark"
          label="备注"
        ></el-table-column>
        <el-table-column label="农事实景" align="center" min-width="100">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              :disabled="scope.row.imgs && scope.row.imgs.length == 0"
              @click="handleViewPic2(scope.row.imgs)"
              >查看图片{{
                scope.row.imgs && scope.row.imgs.length
                  ? "(" + scope.row.imgs.length + "张)"
                  : ""
              }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          property="created_time"
          label="农事时间"
        ></el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          background
          hide-on-single-page
          @size-change="handleSizeChange4"
          @current-change="handleCurrentChange4"
          :current-page="currentPage4"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="Number(listQuery4.count)"
          layout="total, sizes, prev, pager, next, jumper"
          :total="farmRecordListTotal"
        ></el-pagination>
      </div>
    </el-dialog>

    <el-dialog
            title="销售收款信息"
            :visible.sync="table3DialogVisible"
            width="55%"
    >
      <el-form style="text-align: left" :inline="true">
        <el-form-item size="small">
          <el-input
                  placeholder="请输入流水单号"
                  v-model="listQuery5.trx_id"
                  clearable
                  class="input-with-select"
                  @clear="initData2"
          >
          </el-input>
        </el-form-item>
          <el-form-item size="small">
            <el-date-picker
                    v-model="time"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="支付开始时间"
                    end-placeholder="支付结束时间"
                    :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-button type="primary" @click="initData3" size="small"
          >查询</el-button>
      </el-form>
      <el-table
              border
              fit
              :data="flowRecordList"
              empty-text="暂无数据"
              height="450"
      >
        <el-table-column
                width="200"
                align="center"
                property="trx_id"
                label="流水单号"
        ></el-table-column>
        <el-table-column
                align="center"
                property="money"
                label="金额"
        ></el-table-column>
        <el-table-column align="center"  property="status" label="状态">
          <el-tag type="success">已支付</el-tag>
        </el-table-column>
        <el-table-column width="210" align="center"  property="pay_time" label="支付时间">
        </el-table-column>
        <el-table-column width="210" align="center"  property="created_time" label="订单创建时间">
        </el-table-column>
        <el-table-column label="销售商品" align="center" min-width="100">
          <template slot-scope="scope">
            <el-button
                    size="small"
                    type="primary"
                    @click="handleSalesGoods(scope.row.id)"
            >查看销售商品</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
                background
                hide-on-single-page
                @size-change="handleSizeChange5"
                @current-change="handleCurrentChange5"
                :current-page="currentPage5"
                :page-sizes="[10, 20, 30, 50]"
                :page-size="Number(listQuery5.count)"
                layout="total, sizes, prev, pager, next, jumper"
                :total="flowRecordListTotal"
        ></el-pagination>
      </div>
    </el-dialog>

    <el-dialog
            title="实时监测"
            :visible.sync="camDialogVisible"
            width="90%"
            top="5vh"
    >
      <div class="haccp-bg p15" style="height: 1100px;display: flex">
        <el-row :gutter="0" style="width: 100%">
            <el-col :span="8" v-for="(item,index) in camList" :key="index" v-show="camList.length > 0" class="card-item mr20" >
              <div class="card-item-title">
                <div></div>
                <div>
                  {{item.cam_name}}
                </div>
                <div class="button_hover" @click="handleReplyPics(item.id)">留影回看</div>
              </div>
              <div class="card-item-content">
                <live-video :id="item.cam_id" :key="item.cam_id"></live-video>
                <!-- <div :id="'wrapper'+item.id" style="height: 320px;"></div> -->
              </div>
            </el-col>
        <div v-show="camList.length == 0" class="col-fff f25">
          <span>暂未绑定摄像头</span>
        </div>
        </el-row>
      </div>
    </el-dialog>


    <el-dialog title="留影回看" :visible.sync="picsVisible" width="35%">
      <div>
        <div>
          <el-date-picker
                  v-model="timeValue"
                  type="datetime"
                  placeholder="选择日期时间"
                  style="width: 100%"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                  @change="handleTimeChange"
          >
          </el-date-picker>
        </div>
        <div style="margin-top: 10px" v-loading="picLoading">
          <el-image :src="imageSrc" :preview-src-list="[imageSrc]" style="width: 100%"> </el-image>
        </div>
        <el-row type="flex" class="row-bg" justify="space-around">
          <el-col :span="6" style="text-align: center">
            <el-button size="mini" @click="handlePre">上一张</el-button>
          </el-col>

          <el-col :span="6" style="text-align: center">
            <el-button size="mini" @click="handleNext">下一张</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <el-dialog
            title="销售商品记录"
            :visible.sync="table4DialogVisible"
            width="55%"
    >
      <el-form style="text-align: left" :inline="true">
        <el-form-item size="small">
          <el-input
                  placeholder="请输入商品名称"
                  v-model="listQuery6.goods_name"
                  clearable
                  class="input-with-select"
                  @clear="initData2"
          >
          </el-input>
        </el-form-item>
        <el-button type="primary" @click="handleSalesGoods(null)" size="small"
        >查询</el-button>
      </el-form>
      <el-table
              border
              fit
              :data="salesGoodsRecordList"
              empty-text="暂无数据"
              height="450"
      >
        <el-table-column
                width="210"
                align="center"
                property="gtin"
                label="商品编号"
        ></el-table-column>
        <el-table-column
                width="210"
                align="center"
                property="name"
                label="商品名"
        ></el-table-column>
        <el-table-column width="210" align="center"  property="batch_number" label="相关批号">
        </el-table-column>
        <el-table-column align="center"  property="goods_weight" label="销售重量(kg)">
        </el-table-column>
        <el-table-column align="center"  property="price" label="销售单价(元)">
        </el-table-column>
        <el-table-column  align="center"  property="user_name" label="销售者">
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
                background
                hide-on-single-page
                @size-change="handleSizeChange6"
                @current-change="handleCurrentChange6"
                :current-page="currentPage6"
                :page-sizes="[10, 20, 30, 50]"
                :page-size="Number(listQuery6.count)"
                layout="total, sizes, prev, pager, next, jumper"
                :total="salesGoodsRecordListTotal"
        ></el-pagination>
      </div>
    </el-dialog>

    <el-image-viewer
      style="z-index: 9999"
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="[url]"
    />
    <el-image-viewer
      style="z-index: 9999"
      v-if="showViewer1"
      :on-close="closeViewer1"
      :url-list="url1"
    />
  </div>
</template>

<script>
// import axios from "@/util/api.js";
import axios from "../../../util/api";
import  api from '../../../util/extra-api'
import { diffTime, deepClone, createHash } from "@/util/util";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { mapConfig } from "./custom_map_config.js";
import { local } from "../../../util/util";
import LiveVideo from "./components/LiveVideo";
import dayjs from "dayjs";
import CustomStep from "../../../components/customStep";
export default {
  components: {
    ElImageViewer,
    LiveVideo,
    CustomStep
  },
  data() {
    this.chartSettings = {
      key: "Ns87bUghsWzPirriDs7uPGPYQOlGUKYQ",
      bmap: {
        center: [120, 30],
        zoom: 5,
        roam: true,
        enableMapClick: false,
        mapStyleV2: { styleJson: mapConfig },
      },
    };
    this.chartTooltip = {
      show: true,
      formatter: function (params, ticket, callback) {
        if (params.seriesType === "effectScatter") {
          return `${params.marker}${params.value[2]}`;
        } else if (params.seriesType === "lines") {
          return `${params.data.fromName} > ${params.data.toName}`;
        }
      },
    };
    let _this = this;
    this.chartEvents = {
      click: (v) => {
        // console.log("v: ", v);
        _this.$nextTick((_) => {
          _this.companyInfo.company_name = v.value[2];
          _this.companyInfo.address = v.value[4];
          _this.companyInfo.company_type_id = v.value[5];
          _this.companyId = v.value[3];
          var echarts = this.$refs.mapRef.echarts;
          var map = echarts.getModel().getComponent("bmap").getBMap();
          var point = new window.BMap.Point(v.value[0], v.value[1]);
          var infoWindow = new window.BMap.InfoWindow(
            _this.$refs.customInfowindow
          );
          // if (this.clickTimes == 0) {
          //   map.centerAndZoom(point, 14);
          // }
          map.setCenter(point);
          map.openInfoWindow(infoWindow, point);
          this.clickTimes++;
        });
      },
      finished: (_) => {
        // _this.getGeo();
      },
    };
    this.chartSettingsEvaluate = {
      roseType: "radius"
    };
    this.chartExtendEvaluate = {
      legend: {
        textStyle: {
          color: "#ffffff" //字体颜色
        }
      }
    };
    return {
      camId: null,
      timeValue: "",
      imageSrc: "",
      picLoading: false,
      picsVisible: false,
      companyId: null,
      dataType: null,
      barcode: "",
      companyList: [],
      chartSeries: [],
      srlistPage: 1,
      rrlistPage: 1,
      recordData: {},
      companyInfo: {
        company_name: "",
        address: "",
        company_type_id: "",
      },
      storageData: [],
      unitData: [],
      filterForm: {
        flag: 0,
        goodsName: "",
        batchNumber: "",
        gtin: "",
      },
      filterRecordForm: {
        goodsName: "",
        gtin: "",
        batchNumber: "",
        carNumber: "",
        goodsType: 5,
      },
      recordDialogVisible: false,
      supDialogVisible: false,
      storageDialogVisible: false,
      unitDialogVisible: false,
      picDialogVisible: false,
      attachList: [],
      reportList: [],
      options: [
        {
          key: 1,
          value: "资证过期",
          label: "资证过期",
        },
        {
          key: 2,
          value: "未履行查验义务",
          label: "未履行查验义务",
        },
      ],
      levelOptions: [
        {
          value: "A级",
          label: "A级",
        },
        {
          value: "B级",
          label: "B级",
        },
        {
          value: "C级",
          label: "C级",
        },
      ],
      clickTimes: 0,
      level: "",
      reason: "",
      file: "",
      search: "",
      actionPath:
        process.env.VUE_APP_BASE_API + "/api/v1/department/sup_upload",
      baseUrl: process.env.VUE_APP_BASE_API,
      companyListFlag: true,
      hashFlag: false,
      hashVal: createHash(30),
      listQuery: {
        pager_offset: "0",
        pager_openset: "100",
        department_type: "",
      },
      currentPage: 1,
      total: null,
      listLoading1: false,
      list1: [],
      listQuery1: {
        opt: "1",
        pager_offset: "0",
        count: "20",
        company_id: "",
      },
      currentPage1: 1,
      listTotal1: 0,
      table1DialogVisible: false,
      table2DialogVisible: false,
      table3DialogVisible: false,
      table4DialogVisible:false,
      camDialogVisible:false,
      listLoading2: false,
      list2: [],
      listQuery2: {
        pager_offset: "0",
        count: "20",
        name: "",
        base_name: "",
        company_id: "",
      },
      listQuery3: {
        opt: "1",
        pager_offset: "0",
        count: "20",
        planting_id: "",
      },
      listQuery4: {
        opt: "1",
        pager_offset: "0",
        count: "20",
        planting_id: "",
      },
      listQuery5: {
        trx_id:"",
        opt: "1",
        pager_offset: "0",
        count: "20",
        company_id:"",
        add_time:"",
        end_time:"",
      },
      listQuery6: {
        company_id: "",
        page: 1,
        count: 9,
      },
      camListQuery: {
        company_id: "",
        page: 1,
        count: 6,
      },
      camList: [],
      currentPage2: 1,
      currentPage3: 1,
      currentPage4: 1,
      currentPage5:1,
      currentPage6:1,
      listTotal2: 0,
      plantingGoodsList: [],
      plantingGoodsListTotal: 0,
      goodsDialogVisible: false,
      farmRecordList: [],
      farmRecordListTotal: 0,
      farmDialogVisible: false,
      flowRecordList: [],
      flowRecordListTotal: 0,
      flowDialogVisible: false,
      salesGoodsRecordList:[],
      salesGoodsRecordListTotal: 0,
      salesGoodsDialogVisible: false,
      url: "",
      url1: [],
      showViewer: false,
      showViewer1: false,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time:[],
      showHACCP: false,
      showHACCPDetail: false,
      haccpQuery1:{
        page: 1,
        limit: 10,
        keyword: '',
        companyId: null
      },
      haccpQuery2:{
        page: 1,
        limit: 10,
        keyword: '',
        companyId: null
      },
      haccpDetailQuery:{
        page: 1,
        limit: 10,
        keyword: '',
        companyId: null
      },
      haccpList:[
              {
        id: 1,
        name: '方便面',
        count: 10,
        createTime: '2022-3-21 10:52:00'
      },{
        id: 2,
        name: '牛肉干',
        count: 10,
        createTime: '2022-3-21 10:52:00'
      },
      ],
      haccpDetailList: [],
      showEvaluate: false,
      evaluateList:[],
      showEvaluateChart: true,
      tableKey: 0, // 表格的键
      evaluateTableList: null, // 表格的数据
      evaluateTotal: null, // 表格的数据数量
      listLoading: true, // 表格加载状态
      tableHeight: 450,
      evaluateListQuery: {
        companyId: null,
        page: 1, // 表格的页面值
        limit: 20 // 表格的单页数量
      },
      imgList: [],
      imgDialogWidth: null,
      replyDialogVisible: false,
      imgVisible: false,
      haccpTotal: 0,
      haccpDetailTotal: 0,
      haccpCurrentPage: 1,
      haccpDetailCurrentPage: 1,
      HACCPDetailId: null,
      haccpType: 0
    };
  },
  computed: {},
  created() {

  },
  filters: {
    tagFormat(status) {
      const tagMap = {
        0: "primary",
        1: "success",
      };
      return tagMap[status];
    },
    statusFormat(status) {
      const statusMap = {
        0: "未收货",
        1: "已收货",
      };
      return statusMap[status];
    },
    timeFormat(timeString) {
      return diffTime(timeString);
    },
    infoFilter(info) {
      let goodsCategory = JSON.parse(info);
      if (goodsCategory) {
        if (goodsCategory[0] == "国产" || goodsCategory[0] == "") {
          return "国产";
        } else if (goodsCategory[0] == "进口") {
          return "进口";
        }else if(goodsCategory[0]=="农产品"){
          return "农产品"
        }else if(goodsCategory[0]=="农资"){
          return "农资"
        }
      } else {
        return "国产";
      }
    },
    tagFilter(status) {
      //console.log("status: ", status);
      const tagMap = {
        0: "success",
        1: "warning",
      };
      return tagMap[status];
    },
    statusFilter(status) {
      const statusMap = {
        0: "种养中",
        1: "种养结束",
      };
      return statusMap[status];
    },
  },
  mounted() {
    this.currentPage =
      parseInt(this.listQuery.pager_offset) /
        parseInt(this.listQuery.pager_openset) +
      1;
    this.userData = local.get("user");
    this.listQuery.department_type = String(this.userData.department_type);
    this.initMap();
  },
  watch:{
    showEvaluate(v){
      if(!v)
        this.showEvaluateChart = true
    },
    showHACCP(v){
      if(!v){
        this.haccpQuery1.page = 1
        this.haccpQuery1.keyword = ''
        this.haccpCurrentPage = 1
      }
    },
    showHACCPDetail(v){
      if(!v){
        this.haccpQuery2.page = 1
        this.haccpDetailCurrentPage = 1
      }
    }
  },
  methods: {
    checkEvaluate(){
      this.evaluateListQuery.companyId = this.companyId
      this.getEvaluateTable()
    },
    getEvaluateTable(){
      api.get('/v1/pc/evaluation/evaluateList',this.evaluateListQuery).then(response => {
        const results = response.data;
        this.evaluateTableList = results;
        this.evaluateTotal = response.total;
        this.showEvaluateChart = false
      }).catch(err =>{
        this.$message({
          type: 'warning',
          message: err
        })
      });
    },
    // 表格单页数据数量的切换
    handleEvaluateSizeChange(val) {
      this.listQuery.count = val;
      this.getEvaluateTable();
    },
    // 表格页数的切换
    handleEvaluateCurrentChange(val) {
      this.listQuery.page = val;
      this.getEvaluateTable();
    },
    showImg(row) {
      this.imgList = row.imgList;
      this.imgVisible = true;
    },
    handleEvaluate(){
      api.get('/v1/pc/evaluation/evaluateChartData',{companyId: this.companyId}).then(res =>{
        if(res.code === 200){
          res.data.forEach(r => {
            r.chartData = {};
            r.chartData.columns = ["key", "value"];
            r.chartData.rows = r.data;
            delete r.data;
          });
          this.evaluateList = res.data;
          this.showEvaluate = true
        }
      }).catch(err =>{
        this.$message({
          type: 'warning',
          message: err
        })
      })
    },
    goHACCPDetail(id){
      this.HACCPDetailId = id
      let data = {
        id,
        ...this.haccpQuery2
      }
      api.get("/v1/pc/haccpScreen/haccpRecordList",data).then((res) => {
        if(res.code === 200){
          this.showHACCPDetail = true
          this.haccpDetailTotal = res.total
          this.haccpDetailList = res.data
        }

      });
      //this.showHACCPDetail = true

    },
    currentChange(page) {
      this.haccpCurrentPage = page;
      this.haccpQuery1.page = page;
      this.handleHACCP();
    },
    currentDetailChange(page) {
      this.haccpDetailCurrentPage = page;
      this.haccpQuery2.page = page;
      this.goHACCPDetail(this.HACCPDetailId);
    },
    handleHACCP(action){
      if(action){
        this.haccpQuery1.page = 1
        this.haccpCurrentPage = 1
      }
      this.haccpQuery1.companyId = this.companyId
      api.get("/v1/pc/haccpScreen/proList",this.haccpQuery1).then((res) => {
        if(res.code === 200){
          this.haccpList = res.data
          this.haccpTotal = res.total
          this.showHACCP = true
          this.haccpType = 0
        }

      });
      //this.showHACCP = true
    },
    handleCateringHACCP(action){
      if(action){
        this.haccpQuery1.page = 1
        this.haccpCurrentPage = 1
      }
      this.haccpQuery1.companyId = this.companyId
      api.get("/v1/pc/haccpScreen/cateringHaccpRecordList",this.haccpQuery1).then((res) => {
        if(res.code === 200){
          this.showHACCPDetail = true
          this.haccpDetailTotal = res.total
          this.haccpDetailList = res.data
          this.haccpType = 1
        }

      });
      //this.showHACCP = true
    },
    handleReplyPics(cam_id) {
      this.timeValue = dayjs().format("YYYY-MM-DD HH:mm");
      this.camId = cam_id;
      this.picLoading = true;
      axios.get("/pc/company-cam/camImg",{ camId : this.camId, time: this.timeValue }).then((response) => {
        this.imageSrc = response.data;
        this.picLoading = false;
        this.picsVisible = true;
      });
    },
    handleTimeChange(time) {
      this.picLoading = true;
      // cam
      //         .get_cam_pic({ cam_id: this.camId, time: this.timeValue })
      //         .then((response) => {
      //           this.src = response.data.data;
      //           this.picLoading = false;
      //         });
    },
    handlePre() {
      this.picLoading = true;
      this.timeValue = dayjs(this.timeValue)
              .subtract(1, "minute")
              .format("YYYY-MM-DD HH:mm");
      axios.get("/pc/company-cam/camImg",{ camId : this.camId, time: this.timeValue })
              .then((response) => {
                this.imageSrc = response.data;
                this.picLoading = false;
              });
    },
    handleNext() {
      this.picLoading = true;
      this.timeValue = dayjs(this.timeValue)
              .add(1, "minute")
              .format("YYYY-MM-DD HH:mm");
      axios.get("/pc/company-cam/camImg",{ camId : this.camId, time: this.timeValue })
              .then((response) => {
                this.imageSrc = response.data;
                this.picLoading = false;
              });
    },
    initMap() {
      api
        .get(`/v1/pc/area/subordinateEnterprise`, {companyId: local.get("company_id")})
        .then((response) => {
          let res = response.data;
          this.total = response.total;
          this.companyList = deepClone(res);
          let companiesData = this.convertData(res);
          let arr = [];
          companiesData.forEach((item, index) => {
            arr.push({
              type: "effectScatter",
              coordinateSystem: "bmap",
              zlevel: 2,
              rippleEffect: {
                //涟漪特效
                period: 4, //动画时间，值越小速度越快
                brushType: "stroke", //波纹绘制方式 stroke, fill
                scale: 4, //波纹圆环最大限制，值越大波纹越大
              },
              symbol: "circle",
              itemStyle: {
                normal: {
                  color: "#19d4ae",
                },
              },
              data: item,
            });
          });
          this.chartSeries = arr;
          this.getGeo();
        });
    },
    afterSet: function (echarts) {
      var bmap = echarts.getModel().getComponent("bmap").getBMap();
      bmap.addControl(new window.BMap.MapTypeControl());
    },
    handleSizeChange(val) {
      this.listQuery.pager_openset = val + "";
      this.initMap();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.listQuery.pager_offset =
        (val - 1) * this.listQuery.pager_openset + "";
      this.initMap();
    },
    restFilterForm() {
      this.filterForm = {
        flag: 0,
        goodsName: "",
        batchNumber: "",
        gtin: "",
      };
    },
    restFilterRecordForm() {
      this.filterRecordForm = {
        goodsName: "",
        gtin: "",
        batchNumber: "",
        carNumber: "",
        goodsType: 3,
      };
    },
    convertData(data) {
      let result = [];
      for (let i = 0; i < data.length; i++) {
        let formattedData = [
          [
            data[i].longitude,
            data[i].latitude,
            data[i].companyName,
            data[i].companyId,
            data[i].address,
          ],
        ];
        result.push(formattedData);
      }
      return result;
    },
    async handleRecord() {
      this.srlistPage = 1;
      this.rrlistPage = 1;
      await Promise.all([this.fetchSendRecords(), this.fetchReceiveRecords()]);
      this.recordDialogVisible = true;
    },
    async fetchSendRecords() {
      await this.$extraApi
        .get(`/v1/pc/area/receiptAndDeliveryData`, {
          companyId: this.companyId,
          ...this.filterRecordForm,
          type: 1,
          page: this.srlistPage,
        })
        .then((response) => {
          let res = response.data;
          res.forEach((item) => {
            item.hashFlag = false;
            item.hashVal = createHash(30);
          });
          this.$set(this.recordData, "send_out_records", res);
          this.$set(this.recordData, "send_out_records_total", response.total);
        });
    },
    async fetchReceiveRecords() {
      await this.$extraApi
        .get(`/v1/pc/area/receiptAndDeliveryData`, {
          companyId: this.companyId,
          ...this.filterRecordForm,
          type: 2,
          page: this.rrlistPage,
        })
        .then((response) => {
          let res = response.data;
          res.forEach((item) => {
            item.hashFlag = false;
            item.hashVal = createHash(30);
          });
          this.$set(this.recordData, "received_records", res);
          this.$set(this.recordData, "received_records_total", response.total);
        });
    },
    handleCurrentChangeSr(val) {
      this.srlistPage = val;
      this.fetchSendRecords();
    },
    handleCurrentChangeRr(val) {
      this.rrlistPage = val;
      this.fetchReceiveRecords();
    },
    handleStorage() {
      this.$extraApi
        .get(`/v1/pc/area/store`, {
          companyId: this.companyId,
          limit: 1000,
          ...this.filterForm,
        })
        .then((response) => {
          let res = response.data;
          this.storageData = res;
          this.storageDialogVisible = true;
        });
    },
    handleUnit() {
      this.$extraApi
        .get(`/v1/pc/area/supply`, { companyId: this.companyId })
        .then((response) => {
          let res = response.data;
          this.unitData = res;
          this.unitDialogVisible = true;
        });
    },
    handleSup() {
      this.level = "";
      this.reason = "";
      this.file = "";
      this.supDialogVisible = true;
    },
    handleRemove(file, fileList) {
      this.file = "";
    },
    handlePreview(file) {},
    handleSuccess(file, fileList) {
      this.file = file.data.file_name;
    },
    async recordSortChange({ column, prop, order }, type) {
      this.filterRecordForm.sort_key = type;
      this.filterRecordForm.sort_status = order;
      await this.handleRecord();
    },
    handleCreateSup() {
      sup_record
        .save({
          company_id: this.companyId,
          level: this.level,
          reason: this.reason,
          file: this.file,
        })
        .then((response) => {
          this.$notify({
            title: "成功",
            message: "监管成功",
            type: "success",
          });
          this.supDialogVisible = false;
        });
    },
    sortChange({ column, prop, order }) {
      this.filterForm.sort_key = prop;
      order == "ascending"
        ? (this.filterForm.sort_status = 1)
        : (this.filterForm.sort_status = 0);
      this.handleStorage();
    },
    handleRowClick(row, column, event) {
      if (row.longitude && row.latitude) {
        this.$nextTick((_) => {
          this.companyInfo.company_name = row.companyName;
          this.companyInfo.address = row.address;
          this.companyInfo.company_type_id = row.company_type_id;
          this.companyId = row.companyId;
          let echarts = this.$refs.mapRef.echarts;
          let map = echarts.getModel().getComponent("bmap").getBMap();
          let point = new window.BMap.Point(row.longitude, row.latitude);
          let infoWindow = new window.BMap.InfoWindow(
            this.$refs.customInfowindow
          );
          // if (this.clickTimes == 0) {
          //   map.centerAndZoom(point, 14);
          // }
          map.setCenter(point);
          map.openInfoWindow(infoWindow, point);
          this.clickTimes++;
        });
      } else {
        this.$message({
          message: "缺少定位信息",
          type: "warning",
        });
      }
    },
    handleTable1() {
      this.listQuery1.company_id = this.companyId;
      this.initData1();
      this.table1DialogVisible = true;
    },
    handleTable2() {
      this.listQuery2.company_id = this.companyId;
      this.initData2();
      this.table2DialogVisible = true;
    },
    handleTable3() {
      this.listQuery5.company_id = this.companyId;
      this.listQuery5.trx_id = "";
      this.time =[];
      this.initData3();
      this.table3DialogVisible = true;
    },
    handleCam() {
      this.camListQuery.company_id = this.companyId
      //this.camListQuery.company_id = "50111"
      this.initCam()
      this.camDialogVisible = true;
    },
    initData3(){
      if(this.time.length>0){
        this.listQuery5.add_time = this.time[0];
        this.listQuery5.end_time = this.time[1];
      }
      axios.get("/pc/company-flow-record/list",this.listQuery5).then(res=>{
        if (res.code==200){
          this.flowRecordList = res.data.company_flow_list;
          this.flowRecordListTotal = res.data.company_flow_count;
        }
      })
    },
    initCam(){
      axios.get("/pc/company-cam/list",this.camListQuery).then(res=>{
        if (res.code==200){
          this.camList = res.data.cam_list
        }
      })
    },
    getGeo() {
      setTimeout((_) => {
        let pointArr = [];
        this.companyList.forEach((item) => {
          pointArr.push(new window.BMap.Point(item.longitude, item.latitude));
        });
        let echarts = this.$refs.mapRef.echarts;
        let map = echarts.getModel().getComponent("bmap").getBMap();
        let view = map.getViewport(eval(pointArr));
        let mapZoom = view.zoom > 2 ? view.zoom - 1 : view.zoom;
        let centerPoint = view.center;
        map.centerAndZoom(centerPoint, mapZoom);
      }, 200);

      // let echarts = this.$refs.mapRef.echarts;
      // let map = echarts.getModel().getComponent("bmap").getBMap();
      // let myGeo = new window.BMap.Geocoder();
      // // 将地址解析结果显示在地图上，并调整地图视野
      // myGeo.getPoint(
      //   this.province + this.city + this.district,
      //   function (point) {
      //     if (point) {
      //       map.centerAndZoom(point, 14);
      //     }
      //   },
      //   this.city
      // );
    },
    verifyHash() {
      this.hashFlag = !this.hashFlag;
    },
    handleViewPic(row) {
      this.attachList = [];
      this.reportList = [];
      const { qualificationUrl, realPics } = row;
      if (realPics && realPics !== "null") {
        let realPicsTemp = JSON.parse(realPics);
        this.attachList = realPicsTemp.map((item) => item.url);
      }
      if (qualificationUrl && qualificationUrl !== "null") {
        let qualificationUrlTemp = JSON.parse(qualificationUrl);
        //console.log("img",qualificationUrlTemp);
        if (qualificationUrlTemp) {
           qualificationUrlTemp.forEach((item) => {
            if(item.length){
               item.forEach(f=>{
                 this.reportList.push(f.picUrl);
               })
            }else {
              this.reportList.push(item.picUrl)
            }
          });
        }
      }
      if (this.attachList.length > 0 || this.reportList.length > 0) {
        this.picDialogVisible = true;
      } else {
        this.$message({
          message: "无图片",
          type: "warning",
        });
      }
    },
    handleViewPic1(path) {
      this.url = path;
      this.showViewer = true;
    },
    closeViewer() {
      this.showViewer = false;
    },
    handleInput(val) {
      this.initMap();
    },

    initData1() {
      this.listLoading1 = true;
      axios
        .get("/pc/company-planting-area/list", this.listQuery1)
        .then((response) => {
          let data = response.data.planting_list;
          data.forEach((item) => {
            if (item.img_url) {
              item.imgs = JSON.parse(item.img_url);
            }
          });
          this.list1 = data;
          this.listTotal1 = response.data.planting_count;
          this.listLoading1 = false;
        });
    },
    handleSizeChange1(val) {
      this.listQuery1.count = val;
      this.initData1();
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.listQuery1.pager_offset = String((val - 1) * 10);
      this.initData1();
    },
    initData2() {
      this.listLoading2 = true;
      axios
        .get("/pc/company-planting-category/list", this.listQuery2)
        .then((response) => {
          let data = response.data.planting_category_list;
          this.list2 = data;
          this.listTotal2 = response.data.planting_category_count;
          this.listLoading2 = false;
        });
    },
    handleSizeChange2(val) {
      this.listQuery2.count = val;
      this.initData2();
    },
    handleCurrentChange2(val) {
      this.currentPage2 = val;
      this.listQuery2.pager_offset = String((val - 1) * 10);
      this.initData2();
    },
    handleGoods(id) {
      if (id) {
        this.listQuery3.planting_id = id;
      }
      this.plantingGoodsList = [];
      axios
        .get("/pc/company-planting-category/goodsList", this.listQuery3)
        .then((response) => {
          let data = response.data.planting_goods_list;
          data.forEach((item) => {
            item.imgs = [];
            if (item.img_list) {
              item.imgs = JSON.parse(item.img_list);
            }
          });
          this.plantingGoodsList = data;
          this.plantingGoodsListTotal = response.data.planting_goods_count;
        });
      this.goodsDialogVisible = true;
    },
    handleFarmRecord(id) {
      if (id) {
        this.listQuery4.planting_id = id;
      }
      this.farmRecordList = [];
      axios
        .get("/pc/company-planting-category/farmRecordList", this.listQuery4)
        .then((response) => {
          let data = response.data.planting_farmRecord_list;
          data.forEach((item) => {
            item.imgs = [];
            if (item.img_url) {
              item.imgs = item.img_url;
            }
          });
          this.farmRecordList = data;
          this.farmRecordListTotal = response.data.farmRecord_count;
        });
      this.farmDialogVisible = true;
    },
    handleSalesGoods(id){
      this.table4DialogVisible = true;
      if (id) {
        this.listQuery6.company_flow_id = id;
        this.listQuery6.goods_name = "";
      }
      axios.get("/pc/company-flow-record/goodsList",this.listQuery6)
      .then(res=>{
          if(res.code==200){
            this.salesGoodsRecordList = res.data.company_flow_goods_list;
            this.salesGoodsRecordListTotal = res.data.company_flow_goods_count;
          }
      })
    },
    handleSizeChange3(val) {
      this.listQuery3.count = val;
      this.handleGoods();
    },
    handleCurrentChange3(val) {
      this.currentPage3 = val;
      this.listQuery3.pager_offset = String((val - 1) * 10);
      this.handleGoods();
    },
    handleSizeChange4(val) {
      this.listQuery4.count = val;
      this.handleFarmRecord();
    },
    handleCurrentChange4(val) {
      this.currentPage4 = val;
      this.listQuery4.pager_offset = String((val - 1) * 10);
      this.handleFarmRecord();
    },
    handleSizeChange5(val){
      this.listQuery5.count = val;
      this.initData3();
    },
    handleCurrentChange5(val) {
      this.currentPage5 = val;
      this.listQuery5.pager_offset = String((val - 1) * 10);
      this.initData3();
    },
    handleSizeChange6(val){
      this.listQuery6.count = val;
      this.handleSalesGoods();
    },
    handleCurrentChange6(val) {
      this.currentPage6 = val;
      this.listQuery6.pager_offset = String((val - 1) * 10);
      this.handleSalesGoods();
    },

    handleViewPic2(imgs) {
      let url = imgs.map((item) => item.url);
      this.url1 = url;
      this.showViewer1 = true;
    },
    closeViewer1() {
      this.showViewer1 = false;
    },
  },
};
</script>
<style lang="scss" scoped >
.companies-map {
  position: relative;
  height: 100%;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.chain-block-box {
  position: absolute;
  top: 28px;
  left: 15px;
  z-index: 1099;
  padding: 5px 10px;
  background-color: #1A366A;
  color: #F2F6FC;
  cursor: default;
}
.hash-value {
  color: #409eff;
  cursor: pointer;
  z-index: 3;
}
.hash-result {
  color: #67c23a;
  cursor: pointer;
}
.company-list-box {
  position: absolute;
  top: 28px;
  right: 20px;
  z-index: 1099;
}
.company-list {
  background-color: aliceblue;
}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .company-list {
    background: hsla(0, 0%, 100%, 0.35);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }
}
.companies-map .company-list .box-card {
  width: 350px;
  background-color: inherit;
}
.companies-map .company-list .box-card .el-table__row {
  cursor: pointer;
}
/* .companies-map
  .company-list
  .box-card
  .el-table--striped
  .el-table__body
  tr.el-table__row--striped.current-row
  td,
.el-table__body tr.current-row > td {
  background-color: #ffec8b;
} */
.companies-map .company-list .el-table__body tr.current-row > td {
  background-color: #ffec8b;
}

.companies-map .company-list .box-card .el-table tbody tr:hover > td {
  background-color: #409eff;
  color: white;
}
.el-image-viewer__close {
  color: white;
}
.el-image-viewer__wrapper {
  z-index: 9999999 !important;
}

.card-item {
  flex: 1;
  width: 31%;
  min-width: 31%;
  max-width: 31%;
  height: 400px;
  background: transparent;
  margin-bottom: 30px;
  border: 0.5px solid aqua;
  border-radius: 6px;
}
.card-item-title {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
  background: transparent;
  text-align: center;
  font-size: 18px;
  color: #fff;
  border-bottom: 1px solid gainsboro;
}

.card-item-content {
  padding: 20px 10px;
  height: 20vw
}


/*.el-dialog__body  {*/
/*  padding: 0;*/
/*}*/
$color: #2194E0;
.button_hover {
  width: 80px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  text-decoration: none;
  color: #d3d7d4;
  border: 2px solid $color;
  font-size: 14px;
  display: inline-block;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
&:before {
   content: "";
   background-color: rgba(255,255,255,0.5);
   height: 100%;
   width: 3em;
   display: block;
   position: absolute;
   top: 0;
   left: -4.5em;
   transform: skewX(-45deg) translateX(0);
   transition: none;
 }
&:hover {
   cursor:pointer;
   background-color: $color;
   color: #fff;
   border-bottom: 4px solid darken($color, 10%);
&:before {
   transform: skewX(-45deg) translateX(13.5em);
   transition: all 0.5s ease-in-out;
 }
}
}

  .activated{
    color: #1d953f;
  }
/deep/.el-table--scrollable-y ::-webkit-scrollbar {
  display: none;
}
.haccp-bg {
  transform-origin: 0px 0px 0px;
  background-image: url("../../../assets/image/mapbg.png");
  background-size: cover;
  background-position: 50% 0;
  background-color: rgb(0, 0, 0);
  min-width: auto;
  width: 100%;
  min-height: auto;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}
.evaBg{
  transform-origin: 0px 0px 0px;
  background-image: url("../../../assets/image/mapbg.png");
  background-size: cover;
  background-position: 50% 0;
  background-color: rgb(0, 0, 0);
  min-width: auto;
  width: 100%;
  min-height: auto;
  height: 100%;
  overflow: hidden;
  overflow-x: hidden;
}
.el-card {
  background-color: transparent;
}
.button {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  text-decoration: none;
  color: #33a3dc;
  border: 2px solid $color;
  font-size: 16px;
  display: inline-block;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  &:before {
    content: "";
    background-color: rgba(255,255,255,0.5);
    height: 100%;
    width: 3em;
    display: block;
    position: absolute;
    top: 0;
    left: -4.5em;
    transform: skewX(-45deg) translateX(0);
    transition: none;
  }
  &:hover {
    cursor:pointer;
    background-color: $color;
    color: #fff;
    border-bottom: 4px solid darken($color, 10%);
    &:before {
      transform: skewX(-45deg) translateX(13.5em);
      transition: all 0.5s ease-in-out;
    }
  }
}
.hover{
  cursor: pointer;
  &:hover {
    cursor:pointer;
    color: $color;
    &:before {
      transform: skewX(-45deg) translateX(13.5em);
      transition: all 0.5s ease-in-out;
    }
  }
}
</style>
